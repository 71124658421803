import React, { useRef, useState, useEffect } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import DateSelectionTrigger from './DateSelectionTriger'
import '../../../styles/scss/elements/date-selector.scss'
import { selectAvailabilityState } from '../../../redux/reducers/availability'
import { fetchAvailability } from '../../../redux/actions/fetchAvailability'
import BrandLoader from '../BrandLoader'
import AvailabilityCalendarDay from './AvailabilityCalendarDay'

import nextArrow from '../../../assets/icons/next-arrow.svg'
import prevArrow from '../../../assets/icons/prev-arrow.svg'
import { ReactComponent as Arrow } from '../../../assets/icons/next-arrow.svg'

export function checkDateIsBlocked(day) {
  return day.isBefore(moment(), 'days')
}

const Dates = ({
  startDate,
  endDate,
  onChange,
  minNights,
  guestCounts,
  useCalendarSearch,
  defaultNights,
  widgetType
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)
  const [currentMonth, setCurrentMonth] = useState(
    startDate ? startDate.clone().startOf('month') : moment().startOf('month')
  )
  const availabilityState = useSelector(selectAvailabilityState)
  const calendarContainerRef = useRef(null)

  const localStorageData = JSON.parse(
    localStorage.getItem('vitQueryCardParams')
  )
  const propertyCode = localStorageData?.propertyCode
  const requiresAvailability = useCalendarSearch && !!propertyCode
  const dispatch = useDispatch()

  useEffect(() => {
    if (!requiresAvailability || !isVisible) {
      return
    }
    const monthKey = currentMonth.format('YYYY-MM')
    if (
      !availabilityState.loading?.[monthKey] &&
      !availabilityState.data?.[monthKey]
    ) {
      dispatch(
        fetchAvailability({
          monthKey,
          adults: guestCounts.adults,
          children: guestCounts.children,
          numberOfNights: defaultNights || 3,
          propertyCode
        })
      )
    }
  }, [
    currentMonth,
    propertyCode,
    availabilityState,
    dispatch,
    guestCounts,
    defaultNights,
    isVisible,
    requiresAvailability
  ])

  const currentMonthIsLoading =
    availabilityState.loading?.[currentMonth.format('YYYY-MM')]

  function handleOpenDatePicker(targetInput) {
    let initialFocus = 'startDate'

    if (targetInput === 'endDate' && startDate) {
      initialFocus = 'endDate'
    }

    setFocusedInput(initialFocus)
    setIsVisible(true)
  }

  function getPrice(date) {
    const monthKey = moment(date).format('YYYY-MM')
    const dayKey = moment(date).format('DD')
    const price = get(availabilityState, ['data', monthKey, dayKey])
    return price ? price : null
  }

  let maximumDays = localStorageData?.maxNights ?? 99
  if (startDate && requiresAvailability) {
    let currentDate
    for (let i = 1; i <= 7; i++) {
      currentDate = moment(startDate).add(i, 'days')
      const monthKey = moment(currentDate).format('YYYY-MM')
      const dayKey = moment(currentDate).format('DD')
      const price = get(availabilityState, ['data', monthKey, dayKey])
      if (!price) {
        maximumDays = i
        break
      }
    }
  }

  function isDateAvailable(date) {
    return (
      getPrice(date) !== null ||
      (moment(date).diff(moment(startDate), 'days') <= maximumDays && !endDate)
    )
  }

  function isDayBlocked(date) {
    const isOutsideBookingWindow =
      date.isBefore(moment(), 'days') ||
      date.isAfter(moment().add(18, 'months'))

    return requiresAvailability
      ? !isDateAvailable(date) || !getPrice(date) || isOutsideBookingWindow
      : isOutsideBookingWindow
  }

  return (
    <div className="date-selection">
      <div className={`date-selection-fields ${widgetType}`}>
        <DateSelectionTrigger
          date={startDate}
          handleOpenDatePicker={() => handleOpenDatePicker('startDate')}
          label={
            widgetType === 'banner-alt' ? 'Arrival & Departure' : 'Arrival Date'
          }
          widgetType={widgetType}
          withArrow={widgetType === 'banner-alt'}
          useCalendarSearch={useCalendarSearch}
        />
        {useCalendarSearch && (
          <>
            <div
              className={`arrow-container ${
                widgetType === 'banner-alt' ? '' : 'u-md-max'
              }`}
            >
              <Arrow />
            </div>
            <DateSelectionTrigger
              date={endDate}
              handleOpenDatePicker={() => handleOpenDatePicker('endDate')}
              label={widgetType === 'banner-alt' ? null : 'Departure Date'}
              widgetType={widgetType}
              borderLeft={widgetType === 'banner'}
              isDeparture
            />
          </>
        )}
      </div>
      <div
        className={`calendar-container ${isVisible ? 'visible' : 'hidden'}`}
        ref={calendarContainerRef}
      >
        <DateRangePicker
          enableOutsideDays
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="calendar-card-arrival"
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="calendar-card-departure"
          onDatesChange={({ startDate, endDate }) => {
            if (focusedInput === 'startDate') {
              onChange({ startDate, endDate: null })
              setFocusedInput('endDate')
            } else {
              onChange({ startDate, endDate })
              setIsVisible(false)
            }
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          minimumNights={Number(minNights)}
          numberOfMonths={1}
          daySize={requiresAvailability ? 58 : 50}
          isDayBlocked={date => isDayBlocked(date)}
          isOutsideRange={day =>
            focusedInput === 'endDate' &&
            (day.isBefore(startDate) ||
              day.isAfter(startDate.clone().add(maximumDays, 'days')))
          }
          keepOpenOnDateSelect={true}
          onFocusChange={focusedInput => {
            setFocusedInput(focusedInput)
            if (!focusedInput) {
              setCurrentMonth(startDate.clone().startOf('month'))
            }
          }} // PropTypes.func.isRequired,
          transitionDuration={0}
          onPrevMonthClick={() =>
            setCurrentMonth(moment(currentMonth).add(-1, 'M'))
          }
          onNextMonthClick={() =>
            setCurrentMonth(moment(currentMonth).add(1, 'M'))
          }
          initialVisibleMonth={() => currentMonth}
          renderDayContents={date => {
            return (
              <AvailabilityCalendarDay
                date={date}
                price={requiresAvailability && getPrice(date)}
                isDateAvailable={isDateAvailable(date)}
              />
            )
          }}
          navPrev={<CustomMonthNav text={'Prev'} type="previous" />}
          navNext={<CustomMonthNav text={'Next'} type="next" />}
        />
        {isVisible && !!focusedInput && currentMonthIsLoading && (
          <div className="calendar-loading-overlay">
            <BrandLoader />
          </div>
        )}
      </div>
    </div>
  )
}

const CustomMonthNav = ({ text, type }) => (
  <>
    <img src={type === 'next' ? nextArrow : prevArrow} alt={text} />
    <p className="u-bold">{text}</p>
  </>
)

export default Dates
