import React, { useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import renderHTML from 'react-render-html'
import { get } from 'lodash'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { getLabels } from '../../redux/reducers/configuration'

import Tabs from './Tabs'
import NewReservationForm from './NewReservationForm'

import { ReactComponent as HotelIcon } from '../../assets/icons/bed.svg'
import { ReactComponent as TourIcon } from '../../assets/icons/tour-bus.svg'
import { ReactComponent as CampIcon } from '../../assets/icons/campground.svg'

const utfDiff = 660 - moment().utcOffset()
const now = moment().add(utfDiff, 'minutes')
const tomorrow = now.add(1, 'days')

const Banner = function({ widgetType }) {
  const labels = useSelector(getLabels)
  const initialOptions = JSON.parse(localStorage.getItem('vitQueryCardParams'))

  const addedFromStart = +initialOptions?.minimumNights
    ? +initialOptions.minimumNights
    : initialOptions.defaultStayLength
    ? +initialOptions.defaultStayLength
    : 3

  const isTrade =
    initialOptions?.widgetType === 'vit-trade' &&
    process.env.REACT_APP_TRADE_TARGET_URL
  const campground = get(initialOptions, 'campGround', {})

  const initialArrivalDate =
    get(initialOptions, 'arrivalDate') &&
    moment(initialOptions.arrivalDate).isAfter(now, 'days')
      ? moment(initialOptions.arrivalDate)
      : tomorrow

  const initialDepartureDate =
    get(initialOptions, 'departureDate') &&
    moment(initialOptions.departureDate).isAfter(initialArrivalDate, 'days')
      ? moment(initialOptions.departureDate)
      : moment(initialArrivalDate).add(addedFromStart, 'days')

  const [values, setValues] = useState({
    arrivalDate: initialArrivalDate,
    departureDate: initialDepartureDate,
    rooms: [{ adults: 2, children: 0 }],
    promoCodes: []
  })

  const [selectedTab, setSelectedTab] = useState(
    initialOptions?.initialTab &&
      ['hotelsAndTours', 'tours', 'campground'].some(
        option => option === initialOptions.initialTab
      )
      ? initialOptions?.initialTab
      : 'hotelsAndTours'
  )

  const searchIncludesHotel =
    selectedTab === 'hotelsAndTours' || selectedTab === 'hotels'
  const newReservationForm = (
    <NewReservationForm
      initialOptions={initialOptions}
      values={values}
      onValuesChange={handleValuesChange}
      onSubmit={submit}
      useCalendarSearch={searchIncludesHotel}
      widgetType={widgetType}
      addedFromStart={addedFromStart}
    />
  )

  const tabs = [
    {
      id: 'hotelsAndTours',
      name: 'Hotels',
      visible: true,
      content: newReservationForm,
      icon: <HotelIcon />
    },
    {
      id: 'tours',
      name: labels.tours,
      visible: true,
      content: newReservationForm,
      icon: <TourIcon />
    },
    {
      id: 'campground',
      name: 'Campground',
      visible: false,
      icon: <CampIcon />,
      content: (
        <div className={`campground ${widgetType === 'banner' ? 'left' : ''}`}>
          <div className="description">
            <p>
              {campground.description && renderHTML(campground.description)}
            </p>
          </div>
          {campground.primaryLink?.link && (
            <>
              <a className="btn btn-primary" href={campground.primaryLink.link}>
                {campground.primaryLink.label}
              </a>
            </>
          )}
        </div>
      )
    }
  ]

  if (campground && campground?.description) {
    const campgroundTab = tabs.find(tab => tab.id === 'campground')
    campgroundTab.visible = true
  }

  function submit() {
    const params = new URLSearchParams()
    const { arrivalDate, departureDate, rooms, promoCodes } = values

    params.append('accommodation', selectedTab)
    params.append('startDate', arrivalDate.format('YYYY-MM-DD'))
    params.append(
      'endDate',
      departureDate
        ? departureDate.format('YYYY-MM-DD')
        : arrivalDate.add(2, 'days').format('YYYY-MM-DD')
    )
    params.append(
      'rooms',
      rooms?.map(room => `${room.adults}|${room.children}`)?.join(',') || '2|0'
    )

    if (selectedTab === 'tours') {
      params.append('flow', 'E')
    } else {
      params.append('flow', 'AE')
    }

    if (promoCodes.length > 0) {
      params.append('promoCodes', promoCodes.join(','))
    }

    if (initialOptions?.propertyCode && searchIncludesHotel) {
      params.append('hotelCode', initialOptions.propertyCode)
    }

    const paramString = params.toString()

    let basePath = isTrade
      ? process.env.REACT_APP_TRADE_TARGET_URL
      : process.env.REACT_APP_TARGET_URL
    if (initialOptions?.targetUrl) {
      basePath = initialOptions?.targetUrl
    }
    if (isTrade) {
      const entryPoint = selectedTab === 'tours' ? '/tours' : '/accommodations'
      basePath += entryPoint
    } else {
      const entryPoint = selectedTab === 'tours' ? '/tours' : ''
      basePath += entryPoint
    }
    window.location = `${basePath}?${paramString}`
  }

  function handleValuesChange(values) {
    setValues(values)
  }

  return (
    <div className="reservation-banner">
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        widgetType={widgetType}
      />
    </div>
  )
}
export default Banner
