export default {
  hotel: {
    addAdult: 'Add Adult',
    addChild: 'Add Child',
    addRoom: 'Add Room',
    adult: 'Adult',
    adults: 'Adults',
    arrivingOn: 'Arriving On',
    checkRates: 'Check Rates',
    child: 'Child',
    children: 'Children',
    hotels: 'Hotels',
    hotelsAndTours: 'Hotels + Tours',
    guests: 'Guests',
    imLookingFor: "I'm Looking For",
    nights: 'Nights',
    promoCode: 'Promo Code',
    rateGroupCodeLabel: 'Group ID / Rate Code',
    removeAdult: 'Remove Adult',
    removeChild: 'Remove Child',
    removeRoom: 'Remove Room',
    room: 'Room',
    rooms: 'Rooms',
    roomsAndGuests: 'Rooms & Guests',
    specialCodeMessage: 'Special Code',
    tours: 'Tours'
  }
}
